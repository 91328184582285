<template>
  <div class="container mb-5">
    <div class="pt-2 font-size-xl mb-3">{{ $t("addresses.create") }}</div>

    <errors type="validation_errors"/>

    <address-form
        @addressSubmited="addressSubmited"
        :allow_submission="allow_submission"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AddressForm from "@/views/Addresses/partials/AddressForm.vue";
import Errors from "@/components/Errors.vue";
import {useI18n} from "vue-i18n";
import {useMeta} from "vue-meta";

export default {
  components: {Errors, AddressForm},
  name: "AddressesCreate",
  setup() {
    const {t} = useI18n() // use as global scope
    useMeta({
      title: t("addresses.create"),
    })
  },
  data(){
    return {
      allow_submission: true
    }
  },
  computed: {
    ...mapGetters(['addresses'])
  },
  methods: {
    ...mapActions(["createAddress"]),
    addressSubmited(address) {
      this.allow_submission = false;
      this.createAddress(address).then(() => {
        if (this.addresses.length > 1) {
          this.$router.push("/addresses");
        } else {
          this.$router.replace("/");
        }
      }).finally(() => {
        this.allow_submission = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>


